/**
 * @file standalone/content_block.scss
 *
 * \brief Library file - shared styles for content blocks
 *
 */

@import '../theme-bootstrap';

.content-block {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  b,
  strong {
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &--positioned {
      position: absolute;
      z-index: 3;
      top: 0;
      #{$ldirection}: 0;
    }
    &--positioned-stretched {
      position: absolute;
      z-index: auto;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
  }
  &__line {
    display: block;
    padding-bottom: 12px;
    p {
      margin: 0;
      padding: 0;
    }
    &--link {
      padding: 0 12px 12px;
      span {
        padding: 0 20px;
      }
    }
  }
  &__link {
    padding-bottom: 1em;
  }
  &__links {
    display: block;
    min-height: 1.5em;
    padding-bottom: 12px;
    &--2up {
      > div:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
  // Adding for the cases where we are not using mantle media.
  // We need to make sure we fill containers.
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  &.basic-tout,
  &.video-tout {
    &__content {
      display: block;
    }
    .content-block__line--link {
      display: inline-block;
    }
  }

  &.text-align--#{$ldirection} {
    text-align: $ldirection;
  }
  &.text-align--center {
    text-align: center;
  }
  &.text-align--#{$rdirection} {
    text-align: $rdirection;
  }
}
